// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCs7gnk1KjZ0L1DOV20lWT-5EIaWpcrjCo",
    authDomain: "happihome-86b09.firebaseapp.com",
    databaseURL: "https://happihome-86b09.firebaseio.com",
    projectId: "happihome-86b09",
    storageBucket: "happihome-86b09.appspot.com",
    messagingSenderId: "120778836208",
    appId: "1:120778836208:web:1a884f465bd3764ceb1a35",
    measurementId: "G-XXKD2FLJVP"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
