import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from "../app/services/authGaurd/auth.service";
import { AuthloginService } from "../app/services/authGaurd/authlogin.service";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    canActivate: [AuthloginService],  
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: "app",
    canActivate: [AuthService],
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'about-me',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/about-me/about-me.module').then( m => m.AboutMePageModule)
  },
  {
    path: 'account-settings',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule)
  },
  {
    path: 'address-multiple',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/address-multiple/address-multiple.module').then( m => m.AddressMultiplePageModule)
  },
  {
    path: 'award-certificate',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/award-certificate/award-certificate.module').then( m => m.AwardCertificatePageModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./pages/booking/booking.module').then( m => m.BookingPageModule)
  },
  {
    path: 'cart-detail',
    loadChildren: () => import('./pages/cart-detail/cart-detail.module').then( m => m.CartDetailPageModule)
  },
  {
    path: 'choose-functionality',
    loadChildren: () => import('./pages/choose-functionality/choose-functionality.module').then( m => m.ChooseFunctionalityPageModule)
  },
  {
    path: 'contact-copy',
    loadChildren: () => import('./pages/contact-copy/contact-copy.module').then( m => m.ContactCopyPageModule)
  },
  {
    path: 'contact-us',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'content-page',
    loadChildren: () => import('./pages/content-page/content-page.module').then( m => m.ContentPagePageModule)
  },
  {
    path: 'create-account',
    canActivate: [AuthloginService],
    loadChildren: () => import('./pages/create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'details-gst-pan-bank',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/details-gst-pan-bank/details-gst-pan-bank.module').then( m => m.DetailsGstPanBankPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'identity-verification',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/identity-verification/identity-verification.module').then( m => m.IdentityVerificationPageModule)
  },
  {
    path: 'leads-detail',
    loadChildren: () => import('./pages/leads-detail/leads-detail.module').then( m => m.LeadsDetailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then( m => m.MorePageModule)
  },
  {
    path: 'otp',
    canActivate: [AuthloginService],
    loadChildren: () => import('./pages/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'refer-by-friend',
    loadChildren: () => import('./pages/refer-by-friend/refer-by-friend.module').then( m => m.ReferByFriendPageModule)
  },
  {
    path: 'referal-code',
    loadChildren: () => import('./pages/referal-code/referal-code.module').then( m => m.ReferalCodePageModule)
  },
  {
    path: 'select-location',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/select-location/select-location.module').then( m => m.SelectLocationPageModule)
  },
  // {
  //   path: 'select-service',
  //   loadChildren: () => import('./pages/select-service/select-service.module').then( m => m.SelectServicePageModule)
  // },
  {
    path: 'select-type',
    loadChildren: () => import('./pages/select-type/select-type.module').then( m => m.SelectTypePageModule)
  },
  {
    path: 'service-detail/:id',
    loadChildren: () => import('./pages/service-detail/service-detail.module').then( m => m.ServiceDetailPageModule)
  },
  {
    path: 'service-start',
    loadChildren: () => import('./pages/service-start/service-start.module').then( m => m.ServiceStartPageModule)
  },
  {
    path: 'success-page',
    loadChildren: () => import('./pages/success-page/success-page.module').then( m => m.SuccessPagePageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'upload-image',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/upload-image/upload-image.module').then( m => m.UploadImagePageModule)
  },
  {
    path: 'upload-work',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/upload-work/upload-work.module').then( m => m.UploadWorkPageModule)
  },
  {
    path: 'view-service/:id',
    loadChildren: () => import('./pages/view-service/view-service.module').then( m => m.ViewServicePageModule)
  },
  {
    path: 'wallet',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'work-doc-details/:x',
    loadChildren: () => import('./pages/work-doc-details/work-doc-details.module').then( m => m.WorkDocDetailsPageModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./pages/policy/policy.module').then( m => m.PolicyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
