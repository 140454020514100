import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
// import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  constructor(public toastController: ToastController,
    // private toastr: ToastrService
    ) {}

  async showToast(toastMessage:string) {
    const toast = await this.toastController.create({
      animated: true,
      message: toastMessage,
      position:"bottom",
      duration: 3000,
      keyboardClose: true,
      // closeButtonText : 'Close',
      // showCloseButton: true
    });
    toast.present();
  }

  presentToast(toastMessage:string) {
    this.showToast(toastMessage);
    // this.toastr.success( '',toastMessage,{
    //   timeOut: 2000,
    //   progressBar :true,
    //   closeButton:true
    // });
  }

  presentToastError(toastMessage:string) {
    this.showToast(toastMessage);
    // this.toastr.error( '',toastMessage,{
    //   timeOut: 2000,
    //   progressBar :true,
    //   closeButton:true
    // });
  }

  ngOnInit() {}

}
