import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ToastComponent } from './shared/toast/toast.component';
import { AlertComponent } from './shared/alert/alert.component';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { SetLocationComponent } from './shared/set-location/set-location.component';
import { AgmCoreModule } from '@agm/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
const firebaseConfig = environment.firebase;


@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    SetLocationComponent,
    AlertComponent
  ],
  entryComponents: [SetLocationComponent],
  imports: [BrowserModule,NgMatSearchBarModule,
    HttpModule,
    FormsModule,
    HttpClientModule, 
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireStorageModule,
    MatButtonModule,MatRadioModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB3aNmwDAUd2Vna72olhI3pOWdx8lNLhMY', libraries: ["places"]
    }) ,
    IonicModule.forRoot(), AppRoutingModule, AngularFireAuthModule,
  BrowserAnimationsModule, MatButtonModule, MatCheckboxModule,
  //  ServiceWorkerModule.register('ngsw-worker.js',
    // { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    ToastComponent,
    AlertComponent,
    FCM,
    SmsRetriever,
    LocationAccuracy,
    OpenNativeSettings,
    CallNumber,
    // LaunchNavigator,
    SplashScreen,Geolocation,
    // NativePageTransitions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
